// Eigenes CSS
import '../css/style.scss';

// Einfach mal hallo sagen
//console.log('Hallo Welt');

import 'jquery/dist/jquery';
//import 'superfish/src/js/superfish';
//import 'mmenu-js/src/mmenu';
//import 'slideout/dist/slideout';
//import 'zeynepjs';
import 'jquery-match-height/dist/jquery.matchHeight-min';
//import 'snapjs';
//const Snap = require(`imports-loader?this=>window,fix=>module.exports=0!snapsvg/dist/snap.svg.js`);
//import 'snapsvg';
//import Snap from 'snapsvg';
import Swiper, { Navigation, Pagination, Autoplay, Lazy } from 'swiper';
import 'jquery-ui/ui/position';
//import 'bootstrap/js/dist/tooltip';
//import 'bootstrap/js/dist/collapse';

import 'jquery-is-in-viewport/dist/isInViewport.jquery.min';
//import 'in-viewport';

import Plyr from 'plyr';


const player = new Plyr('#video2');

//import 'resize-observer/dist/resize-observer';

//import Chart from 'chart.js/auto';

// import Chart from 'chart.js/auto';



//var fd_page = document.getElementsByTagName("html")[0];
//fd_page.style.opacity = "0";

(function( $ ){
    $.fn.myfunction = function() {
       console.log('hello world');
       return this;
    }; 
 })( jQuery );


/****************  INIT  *****************************/

var App = function ( ) {

    function initPageload() {
        //console.log("Pageload");
        //jQuery('html').css("opacity", "0.8");
        jQuery('html').fadeTo(300, 1);
    }


    function initSuperfish() {
        //console.log("Superfish");
        //jQuery('ul.wpb-sf-menu').css('background-color','#ffff00');
        //jQuery('.fd-header-main-navigation ul').css('background-color','#ddff00');
        //jQuery('#wpb--menu').superfish({});
        
        jQuery('ul.sf-menu').superfish({
            delay:       1000,                            // one second delay on mouseout
            animation:   {opacity:'show',height:'show'},  // fade-in and slide-down animation
            speed:       'fast',                          // faster animation speed
            onInit: function () {
                //console.log("SF Init");
                jQuery('.wpb-sub-menu-inner').addClass('hidden');
            },
            onShow: function () {
                //console.log("SF Init");
                jQuery(this).removeClass('hidden');
            },
            onBeforeHide: function () {
                //console.log("SF Init");
                jQuery(this).addClass('hidden');
            },
            cssArrows: false
            
        });
    }

    function initMmenu() {
        new Mmenu( "#my-menu" );
        //, {
            // options
            /*
            "extensions": [ 
                "theme-dark",
                "pagedim-black"
            ],
            */
           /*
            navbar: {
                title: "title" // "10.000 Schritte Düsseldorf"
            }
        }, {
            // configuration
            offCanvas: {
                page: {
                    selector: "#ukd10k-page"
                }
            }
        });
        */
    }

    function initShrinkedHeader() {
        let shrink_active = false;
        jQuery(window).scroll(function () {
            var windowwidth = jQuery(window).width();
            //console.log("windowwidth: " + windowwidth);
         //   if (windowwidth > 750) {
             /*
                if (jQuery(window).scrollTop() > 75) {
                    jQuery('#fd-header').addClass('header-preshrinked');
                } else {
                    jQuery('#fd-header').removeClass('header-preshrinked');
                }
                */
                if (jQuery(window).scrollTop() > 133) {
                    jQuery('#fd-header').addClass('header-shrinked');
                    //console.log("shrinked");
                } else if (jQuery(window).scrollTop() < 100) {
                    jQuery('#fd-header').removeClass('header-shrinked');
                }

                if (jQuery(window).scrollTop() > 133) {
                    jQuery('#fd-page').addClass('main-shrinked');
                } else if (jQuery(window).scrollTop() < 100) {
                    jQuery('#fd-page').removeClass('main-shrinked');
                }



                /*
                if (jQuery(window).scrollTop() > 35) {
                    shrink_active = true;
                    
                } else if (jQuery(window).scrollTop() < 5) {
                    shrink_active = false;
                    
                }
                if ( shrink_active ) {
                    jQuery('#fd-header').addClass('header-shrinked');
                    jQuery('#fd-page').addClass('main-shrinked');
                } else {
                    jQuery('#fd-header').removeClass('header-shrinked');
                    jQuery('#fd-page').removeClass('main-shrinked');
                }
                */
          //  }
        });

    }

    function initRefreshAfterResize() {
        var dwidth = jQuery(window).width();
        jQuery(window).bind('resize', function(e){
            var wwidth = jQuery(window).width();
            if(dwidth!==wwidth){
            dwidth = jQuery(window).width();
            if (window.RT) clearTimeout(window.RT);
            window.RT = setTimeout(function(){
                this.location.reload(false); /* false to get page from cache */
            }, 1000);
        }
        });
    }

    function initMatchHeight() {
        //console.log("match Height");
        //jQuery(' .news-teaser-3col > div ').matchHeight({byRow: true});
        //jQuery(' .post ').matchHeight({byRow: true});
        jQuery(' .swiper-slide ').matchHeight({byRow: false});
    }


    function initTooltips() {
        jQuery(function () {
            //jQuery('[data-toggle="tooltip-right"]').tooltip({placement: 'right', html: true});
            //jQuery('[data-toggle="tooltip-top"]').tooltip({placement: 'top', html: true, trigger: 'hover'});
            //jQuery('[data-toggle="tooltip-left"]').tooltip("show");
        })
    }


    function initSwiperNews() {
        var newsSwiper = new Swiper('.posts-row', {
            slidesPerView: 1,
            spaceBetween: 10,
            breakpoints: {
                // when window width is >= 320px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 10
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  }
              },
            loop: true,
            grabCursor: true,
            on: {
                init: function () {
                  console.log('swiper initialized');
                  initMatchHeight();
                },
            },
        })
    }


    function initCookiesreopen() {
        jQuery('.cookiesreopen a').attr("href","javascript: Cookiebot.renew()");
    }


    function initCF7Styling() {
        var spans = document.getElementsByClassName("wpcf7-form-control-wrap");
        for(var i=0;i<spans.length;i++)
        {
            var input = spans[i].nextSibling;
            if(input != null)
            {
                if(input.tagName == "LABEL")
                {
                    spans[i].appendChild(input);
                }   
            }   
        }

        let dstext = jQuery(".wpcf7-checkbox .wpcf7-list-item-label").text();
        dstext = dstext.replace("Datenschutzerklärung", "<a href='/datenschutz/'>Datenschutzerklärung</a>");
        console.log(dstext);
        jQuery(".wpcf7-checkbox .wpcf7-list-item-label").html(dstext);
    }


    function initSwiperSlider() {
        var swiper = new Swiper(".fdSwiper", {
            modules: [Navigation, Pagination, Autoplay, Lazy],
            loop: 'true',
            autoHeight: 'true',
            preloadImages: false,
            lazy: {
                checkInView: false,
                loadPrevNext: true,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: 'true',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
            autoplay: {
                delay: 4000,
            },
        });
    }

    function initSwiperTeam() {
        var swiper = new Swiper(".fdSwiper", {
            modules: [Navigation, Pagination, Autoplay],
            loop: 'true',
            autoHeight: 'false',
            preloadImages: false,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
              /*
            autoplay: {
                delay: 4000,
            },
            */
        });
    }








    return {
        init: function () {
            initPageload();
         //   initSuperfish();
            //initMmenu(); 
        //    initShrinkedHeader();
            initMatchHeight();
        //    initSwiperNews();
            initSwiperTeam();
         //   initZeynep();
        //  initSwiperSlider();
        }
    }
}( );


jQuery(document).ready(function () {
    //console.log('ready  ??');
    App.init();
});



